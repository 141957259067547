<template>
  <div class="page-content-inner pt-5 pr-lg-0">
    <div class="section-header pb-0 mt-2 mb-4">
      <div class="section-header-left">
        <h3 class="uk-text-truncate">
          <i class="uil-books"></i> {{ $t("education.catalog_education") }}
        </h3>
      </div>
    </div>

    <!-- <div class="section-header pb-0 mb-4">
      <div class="section-header-left">
        <form action="" @submit.prevent="applyFilter">
          <input v-model="searchQuery" :placeholder="$t('home.Education_shearing')" type="text"
            @input="isTyping = true" />
        </form>
      </div>
      <div class="section-header-right">
        <select v-model="order_by" class="selectpicker ml-3 mb-0" @change="applyFilter">
          <option value="name">{{ $t("filter.Filter_by_name") }}</option>
          <option value="training_type_id">
            {{ $t("filter.Filter_by_category") }}
          </option>
          <option value="created_at">{{ $t("filter.Filter_by_date") }}</option>
        </select>
      </div>
    </div> -->

    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-1-3@m">
        <nav class="responsive-tab style-3 setting-menu card"
          uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-bottom">
          <ul class="side-list" uk-accordion>
            <li class="">
              <a class="uk-accordion-title" href="#"><i class="uil-book-alt"></i> {{ $t("home.Categories") }}</a>
              <div class="uk-accordion-content">
                <ul class="ks-cboxtags">
                  <li v-for="(item, index) in educationTypes" :key="index">
                    <input id="checkboxOne" v-model="options" :value="item.id" type="checkbox" @change="applyFilter" />
                    <label for="checkboxOne">
                      {{ item.name }}
                    </label>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="uk-accordion-title" href="#"><i class="uil-notes"></i>
                {{ $t('general.training_types') }}
              </a>
              <div class="uk-accordion-content">
                <ul class="ks-cboxtags">
                  <li>
                    <input id="checkboxTypeOne" checked v-model="kinds" :value="0" type="checkbox"
                      @click="applyFilter($event)" />
                    <label for="checkboxTypeOne">{{
                      $t('general.all') }}</label>
                  </li>
                  <li v-for="(item, index) in formattedTrainingKind" :key="index">
                    <input id="checkboxTwo" v-model="kinds" :value="item.id" type="checkbox"
                      @click="applyFilter(item.id)" />
                    <label for="checkboxTwo">
                      {{ item.name }}
                    </label>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div class="uk-width-2-3@m">
        <!-- LOADING BOX -->
        <default-loading v-if="catalogs_loading"></default-loading>
        <template v-else>
          <div class="card rounded">
            <nav class="responsive-tab style-2" style="margin-left: 2rem">
              <ul>
                <li :class="{
                  'uk-active': tabIndex === 0,
                }">
                  <a @click="setFilterItem('show_registered_trainings', 0)">
                    {{ $t("general.i_registered") }}
                    <div class="star-rating">
                      <span class="avg">
                        {{ trainingCounts["countOfRegistered"] ?? 0 }}
                      </span>
                    </div>
                  </a>
                </li>
                <li :class="{
                  'uk-active': tabIndex === 1,
                }">
                  <a @click="setFilterItem('can_register_trainings', 1)">
                    {{ $t("general.i_can_register") }}
                    <div class="star-rating">
                      <span class="avg">
                        {{ trainingCounts["countOfRegistrable"] ?? 0 }}
                      </span>
                    </div>
                  </a>
                </li>
                <li :class="{
                  'uk-active': tabIndex === 2,
                }">
                  <a @click="setFilterItem('requests_trainings', 2)">
                    {{ $t("general.requests") }}
                    <div class="star-rating">
                      <span class="avg">
                        {{ trainingCounts["countOfRequests"] ?? 0 }}
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </nav>
            <template v-for="(subItem, index) in items.data" v-if="items && items.data && items.data.length">
              <hr class="m-0" />
              <div class="uk-grid-small p-4" uk-grid>
                <div class="uk-width-1-4@m">
                  <img :src="subItem.cover_image_link" alt="" style="cursor: pointer" class="rounded" />
                </div>
                <div class="uk-width-expand">
                  <div href="javascript:void(0)">
                    <h5 class="mb-2">
                      <span style="cursor: pointer">
                        {{ subItem.name }}
                      </span>
                      <button type="button" class="btn float-right btn-sm btn-info" v-if="subItem.has_been_assigned"
                        @click="handleOnClickCard(subItem.id)">
                        <i class="uil-play-circle"></i>
                        {{ getBottomTitle(subItem.completion_percentage) }}
                      </button>
                      <template v-else>
                        <template v-if="filters.can_register_trainings">
                          <button type="button" class="btn float-right btn-sm btn-success" style="margin-right: 5px"
                            v-if="subItem.self_registrable_trainings?.length > 0"
                            @click="requestForSelfTraining(subItem.id)">
                            <i class="uil-check-circle"></i>
                            {{ $t("general.sign_up") }}

                          </button>
                        </template>
                        <template v-if="filters.requests_trainings">
                          <button type="button" class="btn float-right btn-sm btn-primary" style="margin-right: 5px"
                            v-if="!subItem.user_request &&
                              !subItem.self_registrable_trainings?.length > 0
                            " @click="requestForTraining(subItem.id)">
                            <i class="uil-bell"></i> {{ $t("general.request") }}
                          </button>
                          <button type="button" class="btn float-right btn-sm btn-secondary disabled"
                            style="margin-right: 5px" v-if="subItem.user_request &&
                              subItem.user_request.training_status_id == 1
                            ">
                            <i class="fa fa-check"></i> {{ $t("general.requested") }}
                          </button>
                          <button type="button" class="btn float-right btn-sm btn-danger" style="margin-right: 5px"
                            v-if="subItem.user_request &&
                              subItem.user_request.training_status_id == 0
                            ">
                            {{ $t("general.reject_request") }}
                          </button>
                        </template>
                      </template>
                    </h5>
                    <p class="mb-0 uk-text-small mt-3">
                      <span class="mr-3 bg-light p-2 mt-1">
                        {{
                          $t("education.section_count", {
                            number: subItem.training_content_count,
                          }) +
                          " / " +
                          $t("education.content_count", {
                            number: subItem.training_content_count,
                          })
                        }}</span>
                    </p>
                    <p class="uk-text-small mb-2 mt-4">
                      {{ subItem.description }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <Pagination v-if="items && items.data && items.last_page !== 1" :page.sync="pageProxy" :paginate="items">
          </Pagination>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import EducationCard from "@/view/components/index/EducationCard";
import Pagination from "@/view/components/dataTable/Pagination";
import moment from "moment";
import store from "@/core/services";
import {
  GET_ITEMS,
  ITEMS,
  CREATE_ITEM,
  LOADING,
  UPDATE_ITEM_BY_ID,
  GET_COUNTS,
} from "@/core/services/store/REST.module";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import { mapGetters } from "vuex";

import training_kind_module, {
  BASE_URL as TRAINING_KIND_URL,
  GET_ITEMS as GET_TRAINING_KINDS,
  MODULE_NAME as MODULE_TRAINING_KIND,
  ERROR,
  ITEMS as TRAINING_KINDS,
} from "@/core/services/store/training_kind.module";
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: "aes", isCompression: true });
const _TRAINING_KIND_MODULE = MODULE_TRAINING_KIND;

export default {
  name: "EducationCatalog",
  components: {
    EducationCard,
    Pagination,
    DefaultLoading,
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(_TRAINING_KIND_MODULE, training_kind_module);
  },
  data() {
    return {
      search: null,
      searchQuery: "",
      isTyping: false,
      searchResult: [],
      options: [0],
      kinds: [0],
      order_by: "name",
      sort: "desc",
      restUrl: "api/training",
      trainingRequestUrl: "api/training/request/",
      educationTypes: [],
      selected_type: {
        id: null,
        name: this.$t("all_categories"),
      },
      filters: {
        page: 1,
        search: "",
        training_type_id: 0,
        training_kind_id: 0,
        can_show_self_registrable_trainings: true,
        show_registered_trainings: true,
      },
      tabIndex: 0,
      selected_type_name: null,
      trainingCategoryUrl: "api/training-type",
      page: 1,
      trainingCounts: [],
    };
  },
  computed: {
    ...mapGetters({
      items: ITEMS,
      catalogs_loading: LOADING,
    }),
    training_kinds: {
      get() {
        return store.getters[_TRAINING_KIND_MODULE + "/" + TRAINING_KINDS];
      },
      set(value) { },
    },
    formattedTrainingKind() {
      let results = [];
      if (this.training_kinds === null || !("data" in this.training_kinds))
        return [];
      this.training_kinds.data.forEach((item) => {
        results.push({
          id: item.id,
          value: item.id,
          name: item.name,
        });
      });
      return results;
    },

    pageProxy: {
      set(value) {
        this.page = value;
        this.applyFilter();
      },
      get() {
        return this.page;
      },
    },
  },
  methods: {
    getTrainingCounts() {
      let self = this;
      this.$store
        .dispatch(GET_COUNTS, {
          url: this.restUrl + "-counts",
          acceptPromise: false,
          showLoading: true,
        })
        .then((result) => {
          self.trainingCounts = result.data;
        });
    },
    searchUser: function (searchQuery) {
      let self = this;
      this.$set(this.filters, "page", this.page);
      // this.$set(this.filters, 'training_type_id', this.selected_type.id)
      this.$set(this.filters, "training_type_id", this.options);
      this.$set(this.filters, "training_kind_id", this.kinds);
      this.$set(this.filters, "search", this.searchQuery);
      this.$set(this.filters, "order_by", this.order_by);
      this.$set(this.filters, "by_user", true);
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        showLoading: true,
        filters: this.filters,
      });
    },
    handleOnClickCard(payload) {
      this.$router.push({
        name: "user.eduction-detail",
        params: { id: payload },
      });
    },
    fixDate(value) {
      return moment().seconds(value).toNow();
    },
    setFilterItem(key, index) {
      this.tabIndex = index;
      this.resetPartialFilters();
      this.filters[key] = "true";
      if (key === "can_register_trainings" || key === 'requests_trainings') {
        this.filters["requests_trainings"] = true;
      } else {
        this.filters["requests_trainings"] = false;
      }
      this.applyFilter();
    },
    resetPartialFilters() {
      this.filters.show_registered_trainings
        ? (this.filters.show_registered_trainings = false)
        : null;
      this.filters.can_register_trainings
        ? (this.filters.can_register_trainings = false)
        : null;
      this.filters.requests_trainings
        ? (this.filters.requests_trainings = false)
        : null;
    },
    requestForTraining(id) {
      this.$store
        .dispatch(CREATE_ITEM, {
          url: this.trainingRequestUrl + id,
          acceptPromise: true,
        })
        .then((result) => {
          if (result) {
            this.applyFilter();
            // this.item.user_request = result.data;
          }
        });
    },
    requestForSelfTraining(item_id) {
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: "api/trainings/" + item_id + "/self-registrable",
          // noLoading: true,
        })
        .then((result) => {
          if (result) {
            this.getTrainingCounts();
            this.applyFilter();
          }
        });
    },
    applyFilter(event = null) {
      if (event?.target?.checked && event?.target?._value === 0) {
        this.kinds = [0, 1, 2, 3];
      } else if (!event?.target?.checked && event?.target?._value === 0) {
        this.kinds = [];
      }
      console.log(this.kinds);
      this.$set(this.filters, "page", this.page);
      // this.$set(this.filters, 'training_type_id', this.selected_type.id)
      if (this.options.length === 0 || (this.options[0] === 0 && this.options.length === 1)) {
        delete this.filters['training_type_id[]'];
        this.filters.training_type_id = 0;
      }
      else if (this.options.length > 0) {
        delete this.filters.training_type_id;
        this.$set(this.filters, "training_type_id[]", this.options);
      }
      if (this.kinds.length === 0 || (this.kinds[0] === 0 && this.kinds.length === 1)) {
        delete this.filters['training_kind_id[]'];
        this.filters.training_kind_id = 0;
        // this.$set(this.filters, "training_kind_id[]", this.kinds);
      }
      else if (this.kinds.length > 0) {
        delete this.filters.training_kind_id;
        this.$set(this.filters, "training_kind_id[]", this.kinds);
      }
      // if (!(this.kinds.length === 1 && this.kinds[0] === 0) && this.kinds.length !== 0) {
      //   this.$set(this.filters, "training_kind_id[]", this.kinds);
      // }else if(){
      // }
      this.$set(this.filters, "search", this.search);
      this.$set(this.filters, "order_by", this.order_by);
      this.$set(this.filters, "by_user", true);
      let currentUserFromStorage = ls.get("user");
      let currentUser = JSON.parse(currentUserFromStorage);
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        showLoading: true,
        filters: this.filters,
      });
    },
    changeTrainingType(item = null) {
      if (item) {
        this.selected_type = item;
      } else {
        this.selected_type.id = 0;
        this.selected_type.name = this.$t("education.all_categories");
      }

      this.applyFilter();
    },
    getBottomTitle(percentage) {
      switch (percentage) {
        case 0:
          return this.$t("general.start");
        case 100:
          return this.$t("general.completed");
        default:
          return this.$t("general.continue");
      }
    },
  },
  watch: {
    searchQuery: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.searchUser(this.searchQuery);
      }
    },
  },
  mounted() {
    if (!this.$isCatalogTrainingVisible) {
      this.$router.push({ name: "user.index" });
    }
    $(".selectpicker").selectpicker();
    this.selected_type_name = this.$t("all_categories");
    let self = this;

    this.$store
      .dispatch(GET_ITEMS, {
        url: this.trainingCategoryUrl,
        acceptPromise: true,
      })
      .then((response) => {
        if (response.status) {
          self.educationTypes = response.data;
          self.educationTypes.unshift({
            id: 0,
            name: this.$t("education.all_categories"),
          });
        }
      });

    store.dispatch(_TRAINING_KIND_MODULE + "/" + GET_TRAINING_KINDS, {
      url: TRAINING_KIND_URL,
    });
    this.getTrainingCounts();
    this.applyFilter();
  },
};
</script>

<style scoped>
ul.ks-cboxtags li input[type="checkbox"] {
  height: 100%;
  background-color: var(--backgroundColor) !important;

}
</style>
